import config from '../tokens/config.json';
import './alert/dashboard.js';
import './dropdown/init.js';
import './flatpickr/init.js';
import { Loader } from './loader.js';
import './select/init.js';
import Select from './select/Select.js';
import Menu from './menu.js';
import './tooltip/init.js';
import 'preline';
import Quill from 'quill';
import { quillConfig } from './utils.js';


window.accordion = Select;
globalThis.accordion = Select;

new Loader().hide();
Menu('menu');

const toggle = (navbar, aside, asideWidth, button, main) => {
    button.addEventListener('click', () => {
        aside.classList.toggle('active');
        aside.classList.toggle('navbars-aside--sm');
        aside.classList.toggle('navbars-aside--full');
        asideWidth = aside.offsetWidth;
        main.style.setProperty('--aside-leftbar-width', asideWidth + 'px');
        navbar.style.setProperty('--aside-leftbar-width', asideWidth + 'px');

        const dropdownItems = aside.querySelectorAll('.dropdown__items');

        dropdownItems.forEach((item) => {
            item.classList.remove('open');
        });

        button.querySelector('i').style.transform =
            button.querySelector('i').style.transform === 'rotate(180deg)'
                ? ''
                : 'rotate(180deg)';
    });
};

const resize = (aside, navbar, main) => {
    window.addEventListener('resize', () => {
        const navbarAsideWidth = aside.offsetWidth;
        navbar.style.setProperty(
            '--aside-leftbar-width',
            navbarAsideWidth + 'px'
        );
        main.style.setProperty(
            '--aside-leftbar-width',
            navbarAsideWidth + 'px'
        );
    });
};
const showPassword = () => {
    const button = document.querySelector(`#password-show-password`);
    button.addEventListener('click', () => {
        const input = document.getElementById(`password`);
        input.type = input.type === 'password' ? 'text' : 'password';
        const icon = button.querySelector(`i`);
        icon.classList.toggle('icons-eye-slash');
        icon.classList.toggle('icons-eye');
    });
};

window.onload = () => {
    import('./tables/init.js')
        .then((module) => {
            module;
            document.querySelectorAll('.dt-input').forEach((element) => {
                new Select(element, {
                    showSearch: true,
                });
            });
        })
        .catch((error) => {
            console.error("Erreur lors de l'import du module :", error);
        });
   
    if (document.querySelector(`#password-show-password`)) {
        showPassword();
    }
};

document.addEventListener('DOMContentLoaded', () => {

    const navbar = document.querySelector('[data-navbars="dashboard"]');
    const navbarAside = document.querySelector('.navbar-aside');
    const navbarToggle = document.querySelector(
        '[data-navbars="dashboard"] .toggle-menu'
    );
    const main = document.querySelector('main');
    let navbarAsideWidth;

    if (navbarAside) {
        // navbarAside.classList.add('navbars-aside--sm');
        navbarAsideWidth = navbarAside.offsetWidth;
        navbar.style.setProperty(
            '--aside-leftbar-width',
            navbarAsideWidth + 'px'
        );
        main.style.setProperty(
            '--aside-leftbar-width',
            navbarAsideWidth + 'px'
        );

        resize(navbarAside, navbar, main);
        toggle(navbar, navbarAside, navbarAsideWidth, navbarToggle, main);
    }

})

if (document.querySelector('.iframe-scroll')) {
    document.querySelector('.iframe-scroll').style.top = `${window.scrollY}px`;
    window.addEventListener('scroll', function(e) {
        document.querySelector('.iframe-scroll').style.top = `${window.scrollY}px`;
    });
}

if (document.getElementById('formTextEditor')) {
    document.getElementById('formTextEditor').addEventListener('submit', (e) => {
        e.preventDefault();
        e.stopPropagation();
        HSOverlay.close(document.getElementById('modalTextEditor'));

        if (document.getElementById('formTextEditor').__quillUpdate) {
            document.getElementById('formTextEditor').__quillUpdate(document.getElementById('formTextEditor').__quill.root.innerHTML);
        }
        
    });
}




if (document.querySelector('#formTextEditor .text-editor-global')) {
    const quill = new Quill(document.querySelector('#formTextEditor .text-editor-global'), {
        ...quillConfig
    });

    document.querySelector('#formTextEditor').__quill = quill;
    
    quill.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
        const plaintext = node.innerText || "";
        const Delta = Quill.import('delta');
        return new Delta().insert(plaintext, {});
    });
    
    quill.on('text-change', (delta, oldDelta, source)  => {
        document.querySelector('#formTextEditor .text-quill-global').value = quill.root.innerHTML;
    });
}
