import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr.js';

dayjs.locale('fr');

flatpickr('.datepicker', {
    locale: French,
    allowInput: true,
    altInput: true,
    dateFormat: 'Y-m-d',
    altFormat: 'd/m/Y',
    altInputClass: '',
    locale: 'fr',
});

flatpickr('.datepicker_time', {
    locale: French,
    allowInput: true,
    altInput: true,
    enableTime: true,
    dateFormat: 'Y-m-d H:i',
    altFormat: 'd/m/Y H:i',
    altInputClass: '',
    locale: 'fr',
});

flatpickr('.datepicker_range', {
    locale: French,
    mode: 'range',
    allowInput: true,
    dateFormat: 'Y-m-d',
    altFormat: 'd/m/Y',
    altInputClass: '',
    locale: 'fr',
    defaultDate: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    ],
    onChange: function(selectedDates, dateStr, instance) {
        if(selectedDates.length == 2){

        }
    }
});